import * as React from 'react';
import {withGlobalProps, IProvidedGlobalProps} from '../../../providers/globalPropsProvider';
import {
  Pagination as PaginationUI,
  PaginationProps,
} from '@wix/wixstores-client-gallery/dist/es/src/gallery/components/ProductList/Pagination';

const PaginationComponent: React.FunctionComponent<IProvidedGlobalProps> = (props) => {
  const {
    currentPage,
    handlePagination,
    totalProducts,
    paginationMode,
    productsPerPage,
    styleParams: {
      booleans: {gallery_paginationFirstLastArrows: showFirstLastNavButtons},
    },
  } = props.globals;

  const paginationProps: PaginationProps = {
    currentPage,
    paginationMode,
    totalPages: Math.ceil(totalProducts / productsPerPage),
    showFirstLastNavButtons,
    handlePagination,
  };

  return (
    <div data-hook="wishlist-pagination">
      <PaginationUI {...paginationProps} />
    </div>
  );
};

export const Pagination = withGlobalProps(PaginationComponent);
